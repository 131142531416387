@import "/public/style/value.scss";

.four-section {
  padding: $content-padding;
  height: $min-height;

  h1 {
    font-size: $header-font-size;
    line-height: 2.375rem;
    font-weight: $font-weight-normal;
    text-align: center;

    > b {
      color: $secondary-color;
    }
  }

  h2 {
    font-size: $subheader-font-size;
    margin: 0;
    text-align: center;
    font-weight: $font-weight-normal;

    > span {
      color: $secondary-color;
    }
  }

  .image-cover {
    width: 100%;
    height: auto;
    position: relative;
  }

  #web-tag {
    position: absolute;
    top: -32px;
    right: 12%;
  }

  #android-tag {
    position: absolute;
    bottom: 32px;
    left: 34%;
  }

  #ios-tag {
    position: absolute;
    left: 3%;
    top: 18%;
  }
}
