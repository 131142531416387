@import "/public/style/value.scss";

.thirteen-section {
  position: relative;
  min-height: 100vh;

  h1.main-title {
    font-size: $header-font-size;
    font-weight: $font-weight-bold;
    color: $primary-color;
    text-align: center;
  }

  h1.sub-title {
    font-size: $header-font-size;
    font-weight: 500;
    color: $primary-color;
    margin-bottom: 0;
    text-align: center;

    b {
      color: $secondary-color;
    }
  }

  h2 {
    font-size: $subheader-font-size;
    font-weight: 400;
    text-align: center;
    max-width: 70ch;
    margin: 0 auto;

    > p {
      margin-bottom: 0;
    }
  }

  .back-door-section,
  .upload-content-section,
  .manage-member-section,
  .upload-banner-section,
  .statistic-section {
    min-height: 100vh;
    padding: $content-padding 0;
  }

  .back-door-section {
    img {
      width: 100%;
      height: auto;
      max-width: 80%;
    }
  }

  .upload-content-section {
    img {
      width: 100%;
      height: auto;
      max-width: 90%;

      @media (max-height: 850px) {
        max-width: 85%;
      }
    }
  }

  .manage-member-section {
    img {
      width: 100%;
      height: auto;
      max-width: 80%;
    }
  }

  .upload-banner-section {
    img {
      width: 100%;
      height: auto;
      max-width: 85%;

      @media (max-height: 850px) {
        max-width: 80%;
      }
    }
  }

  .statistic-section {
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      max-width: 75%;
    }
  }
}
