.manage-device-modal{
   .device-list {
      min-height: 200px;

      .divider {
         margin: 8px 0;
         border-color: var(--grey6);
      }

      .device-item {
         display: flex;
         align-content: center;
         align-items: center;
         justify-content: center;
         color: var(--hti-primary-light);

         img:hover {
            cursor: pointer;
         }

         .device-information {
            flex: 2;

            h3 {
               font-size: 16px;
               margin: 0;
            }

            p {
               font-size: 12px;
               font-family: Prompt;
            }
         }
      }

      .nodata-view {
         max-height: 400px !important;
      }
   }

   .btn-group {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .re-login-btn {
         color: white;
         background-color: #012875;
         border: none;
         &:hover{
            border: none;
         }

         &:disabled{
            background-color: #bdbdbd;
            color: #828282;
         }
      }
   }
}
