.background-container {
   position: relative;
   width: 100%;
   height: 100vh;

   .background-cover {
      position: absolute;
      width: 100%;
      min-width: 3760px;
      height: 1200px;
      overflow: hidden;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -99;

      @media (min-height: 1000px) {
         overflow: visible;
      }

      .image-container {
         position: relative;
         width: 100%;
         height: 100%;
      }
   }

   #background-row-1 {
      position: absolute;
      bottom: -25%;
      left: -30%;
      transform-origin: center;
   }

   #background-row-2 {
      position: absolute;
      bottom: -25%;
      left: -15%;
      transform-origin: center;
   }

   #background-row-3 {
      position: absolute;
      bottom: -25%;
      left: 0;
      transform-origin: center;
   }

   #background-row-4 {
      position: absolute;
      bottom: -25%;
      left: 15%;
      transform-origin: center;
   }

   #background-row-5 {
      position: absolute;
      bottom: -25%;
      left: 30%;
      transform-origin: center;
   }

   #background-row-6 {
      position: absolute;
      bottom: -25%;
      left: 45%;
      transform-origin: center;
   }

   #background-row-7 {
      position: absolute;
      bottom: -25%;
      left: 60%;
      transform-origin: center;
   }

   #background-row-8 {
      position: absolute;
      bottom: -25%;
      left: 75%;
      transform-origin: center;
   }

   .anim-slide-1 {
      -webkit-animation: slide_1 20s infinite;
      animation: slide_1 20s infinite;
      -moz-animation: slide_1 20s infinite;
      animation-timing-function: linear;
   }

   @keyframes slide_1 {
      0% {
         transform: translate(-10px, -10px);
      }
      50% {
         transform: translate(200px, 200px);
      }
      100% {
         transform: translate(-10px, -10px);
      }
   }

   .anim-slide-2 {
      -webkit-animation: slide_2 20s infinite;
      animation: slide_2 20s infinite;
      -moz-animation: slide_2 20s infinite;
      animation-timing-function: linear;
   }

   @keyframes slide_2 {
      0% {
         transform: translate(200px, 200px);
      }
      50% {
         transform: translate(-10px, -10px);
      }
      100% {
         transform: translate(200px, 200px);
      }
   }
}
