@import "/public/style/value.scss";

.six-section {
  min-height: $min-height;
  padding: $content-padding;

  h1 {
    font-size: $header-font-size;
    line-height: 2.25rem;
    font-weight: $font-weight-normal;
    text-align: center;

    > b:first-child {
      color: #000;
    }

    > b:last-child {
      color: $secondary-color;
    }
  }

  h2 {
    font-size: $subheader-font-size;
    line-height: 1.5rem;
    font-weight: $font-weight-normal;
    text-align: center;
    margin-bottom: 24px;

    > b {
      color: $primary-color;
    }
  }

  img {
    width: 100%;
    height: auto;
    max-width: 40%;
  }
}
