@import "/public/style/value.scss";

.eleven-section {
  padding: $content-padding;
  min-height: 100vh;

  h1 {
    font-size: $header-font-size;
    font-weight: 400;
    // line-height: calc($header-font-size + 0.125rem);
    margin-bottom: 0;
    text-align: center;

    b {
      color: $secondary-color;
    }
  }

  h2 {
    font-size: $subheader-font-size;
    font-weight: 400;
    text-align: center;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 95%;
  }
}
