@import "/public/style/value.scss";

.first-section {
	padding: 16px;
	position: relative;
	height: $min-height;
	overflow: hidden;

	@media (min-height: 1100px) {
		max-height: 1100px;
	}

	#top-background {
		position: absolute;
		top: 0;
		right: 0;
		z-index: -3;

		@media (min-width: 1920px) {
			right: 20%;
		}
	}

	#right-background {
		position: absolute;
		top: 0;
		right: 0;
		z-index: -2;
		height: 90%;

		@media (min-height: 1100px) {
			max-width: 50%;
			height: 95%;
		}
	}

	#bottom-background {
		position: absolute;
		bottom: -3%;
		left: 0;
		width: 100%;
		max-height: auto;
		z-index: -1;
		object-fit: cover;
	}

	.content-container {
		max-width: 1600px;
		width: 100%;
	}

	.left-col {
		width: 35%;
		height: 100%;
		margin-right: 48px;

		img {
			max-width: 300px;
			height: auto;
			margin-bottom: 0.5rem;
		}

		h1 {
			margin-top: 16px;
			font-size: 1.25rem;
			font-weight: $font-weight-medium;
			line-height: 1.375rem;
			margin: 10px 0;
			color: $primary-color;
			margin-bottom: 10px;
		}

		h3 {
			margin: 0;
			font-size: 1rem;
			color: $grey-3;
			line-height: 1.7188rem;
			font-weight: $font-weight-normal;
		}

		button {
			font-size: 20px;
			font-weight: $font-weight-medium;
			margin-top: 48px;
		}

		.primary-background-button {
			width: 100%;
			max-width: 250px;
			height: 56px;
		}
	}

	.right-col {
		width: 65%;
		padding-top: 25px;
		z-index: 1;

		> div {
			position: relative;
			padding-bottom: 54.25%;
			padding-top: 25px;
		}

		.player-wrapper {
			position: relative;
			padding-top: 56.25%;
			z-index: 10;
		}

		.react-player {
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 20px;
			border: none;
			box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
				rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
		}

		iframe {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 20px;
			border: none;
			box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
				rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
		}
	}
}
