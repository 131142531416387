.not-found {
   width: 100%;
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #828282;

   svg {
      max-width: 700px;
   }
}
