@import "/public/style/value.scss";

.eight-section {
  padding: $content-padding;
  min-height: 100vh;

  h1 {
    font-size: $header-font-size;
    line-height: 2.25rem;
    font-weight: $font-weight-normal;
    text-align: center;

    > b {
      color: $secondary-color;
    }
  }

  .content-container {
    width: 100%;
  }

  .left-col {
    width: 65%;

    .timer-contain {
      position: relative;
      max-width: 850px;
      margin: 0 auto;
      height: 550px;
    }
  }

  .right-col {
    width: 35%;
  }

  .timer-border {
    overflow: hidden;
    width: 100%;
    min-width: 50vw;

    .timer-group {
      position: relative;
      width: 550px;
    }
  }
}
