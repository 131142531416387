@import "/public/style/value.scss";

.timer-1 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  .percent-1 {
    stroke-dasharray: 100;
    stroke-dashoffset: calc(100 - 75);
  }

  .anim-1 {
    transition: stroke-dashoffset 0.5s ease-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-name: progress;
  }

  @keyframes progress {
    from {
      stroke-dashoffset: 100;
    }
    to {
      stroke-dashoffset: calc(100 - 75);
    }
  }
}

.timer-2 {
  position: absolute;
  top: 40%;
  left: 20%;
  transform: translate(-50%, -50%) scale(0.5);

  .percent-2 {
    stroke-dasharray: 100;
    stroke-dashoffset: calc(100 - 92);
  }

  .anim-2 {
    transition: stroke-dashoffset 0.5s ease-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-name: progress2;
  }

  @keyframes progress2 {
    from {
      stroke-dashoffset: 100;
    }
    to {
      stroke-dashoffset: calc(100 - 92);
    }
  }
}

.timer-3 {
  position: absolute;
  top: 70%;
  left: 25%;
  transform: translate(-50%, -50%) scale(0.5);

  .percent-3 {
    stroke-dasharray: 100;
    stroke-dashoffset: calc(100 - 67);
  }

  .anim-3 {
    transition: stroke-dashoffset 0.5s ease-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-name: progress3;
  }

  @keyframes progress3 {
    from {
      stroke-dashoffset: 100;
    }
    to {
      stroke-dashoffset: calc(100 - 67);
    }
  }
}

.timer-4 {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);

  .percent-4 {
    stroke-dasharray: 100;
    stroke-dashoffset: 0;
  }

  .anim-4 {
    transition: stroke-dashoffset 0.5s ease-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-name: progress4;
  }

  @keyframes progress4 {
    from {
      stroke-dashoffset: 100;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
}

.timer-5 {
  position: absolute;
  top: 70%;
  right: -10%;
  transform: translate(-50%, -50%) scale(0.5);

  .percent-5 {
    stroke-dasharray: 100;
    stroke-dashoffset: calc(100 - 59);
  }

  .anim-5 {
    transition: stroke-dashoffset 0.5s ease-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-name: progress5;
  }

  @keyframes progress5 {
    from {
      stroke-dashoffset: 100;
    }
    to {
      stroke-dashoffset: calc(100 - 59);
    }
  }
}

.timer-6 {
  position: absolute;
  top: 40%;
  right: -15%;
  transform: translate(-50%, -50%) scale(0.5);

  .percent-6 {
    stroke-dasharray: 100;
    stroke-dashoffset: calc(100 - 84);
  }

  .anim-6 {
    transition: stroke-dashoffset 0.5s ease-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-name: progress6;
  }

  @keyframes progress6 {
    from {
      stroke-dashoffset: 100;
    }
    to {
      stroke-dashoffset: calc(100 - 84);
    }
  }
}

.timer-container {
  position: relative;
  width: fit-content;

  .number {
    font-size: 4rem;
    font-weight: $font-weight-bold;
    color: #fff;
  }
}

.rotate {
  transform: rotate(-90deg);
  transform-origin: center;
}

.timer-counter {
  position: absolute;
  top: 25%;
  left: 37%;
  z-index: 3;
}
