@import "/public/style/value.scss";

.twelve-section {
  position: relative;
  min-height: 100vh;

  h1 {
    font-size: $header-font-size;
    font-weight: 400;
    color: #fff;

    margin-bottom: 0;
    text-align: center;

    b {
      color: $secondary-color;
    }
  }

  h2 {
    color: #fff;
    font-size: $subheader-font-size;
    font-weight: 400;
    text-align: center;
  }

  .content-container {
    padding: 7.5rem 0;
  }

  .company-grid {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    gap: 32px 24px;

    .cover-image {
      width: 70px;
      height: 70px;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
      border-radius: 50px;

      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        aspect-ratio: 1;
      }
    }
  }

  .icon-image {
    width: 100%;
    height: auto;
    max-width: 85%;
  }
}
