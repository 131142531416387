body,
html {
	font-family: "Prompt", sans-serif;
	// font-family: "Poppins", sans-serif;
}

.app-wrapper {
	display: flex;
	flex-direction: column;
	min-width: 1376px;
	min-height: 100vh;
	overflow: hidden;
}

.content-container {
	max-width: 1366px;
	margin: 0 auto;
}

#background {
	position: absolute;
	width: 100%;
	max-height: 100%;
	z-index: -1;
}

#background-flip {
	position: absolute;
	width: 100%;
	max-height: 100%;
	transform: scaleX(-1);
	z-index: -1;
}

.title-section {
	margin-bottom: 2rem;
}

.margin-bottom-0 {
	margin-bottom: 0 !important;
}

.margin-bottom-16 {
	margin-bottom: 16px !important;
}

.flex {
	display: flex;
}

.flex-col {
	flex-direction: column;
}

.align-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.ant-modal-close-x {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Button  Style */
.primary-background-button {
	background-color: #012875;
	color: #fff;
	border: none;
}
