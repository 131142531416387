@import "/public/style/value.scss";

.third-section {
  position: relative;
  height: $min-height;
  overflow: hidden;

  h1 {
    font-size: 2.25rem;
    line-height: 2.375rem;
    color: #fff;
    font-weight: 400;
    text-align: center;

    > b {
      color: $secondary-color;
    }
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.625rem;
    color: #fff;
    text-align: center;
    font-weight: 400;
  }

  .content-container {
    padding: 48px;
  }

  .image-cover {
    position: relative;
    width: fit-content;
    margin: 0 auto;

    #mobile-image {
      width: 100%;
      max-width: 650px;
      height: auto;
    }

    #gradient-image {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    #organization {
      position: absolute;
      top: 39%;
      left: -31%;

      span {
        font-size: 1.5rem;
        font-weight: 600;
        color: #fff;
      }

      .arrow {
        margin-left: 16px;
      }
    }

    #banner {
      position: absolute;
      top: 75%;
      left: -22%;

      span {
        font-size: 1.5rem;
        font-weight: 600;
        color: #fff;
      }

      .arrow {
        margin-left: 16px;
      }
    }

    #themeColor {
      position: absolute;
      top: 21%;
      right: -26%;

      span {
        font-size: 1.5rem;
        font-weight: 600;
        color: #fff;
      }

      .arrow {
        margin-right: 16px;
        transform: scaleX(-1);
      }
    }

    #logo {
      position: absolute;
      top: 35%;
      right: -34%;

      span {
        font-size: 1.5rem;
        font-weight: 600;
        color: #fff;
      }

      .arrow {
        margin-right: 16px;
        transform: scaleX(-1);
      }
    }
  }
}
