@import "/public/style/value.scss";

.seven-section {
  position: relative;
  height: $min-height;
  min-height: 800px;
  padding: $content-padding;

  @media (max-width: 1440.98px) {
    padding: 10% $content-padding;
  }

  h1 {
    font-size: 2.25rem;
    line-height: 2.375rem;
    font-weight: 400;
    text-align: center;

    > b {
      color: $secondary-color;
    }
  }

  span {
    width: 100%;
    text-align: center;
    font-size: $subheader-font-size;
    font-weight: $font-weight-medium;
    margin-top: 10px;
    // position: absolute;
    // bottom: -48px;
  }

  .content-container {
    width: 100%;
    height: 100%;
  }

  .left-col {
    width: 50%;
  }

  .cover-image {
    position: relative;

    &:hover {
      img {
        cursor: pointer;
      }

      span {
        bottom: -36px;
        opacity: 1;
      }
    }

    // span {
    //   width: 100%;
    //   text-align: center;
    //   font-size: $subheader-font-size;
    //   font-weight: $font-weight-medium;
    //   margin-top: 10px;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   z-index: -1;
    //   display: block;
    //   margin: 0;
    //   padding: 0;
    //   color: #444;
    //   font-size: 18px;
    //   text-decoration: none;
    //   text-align: center;
    //   -webkit-transition: 0.3s ease-in-out;
    //   transition: 0.3s ease-in-out;
    //   opacity: 1;
    // }

    img {
      width: auto;
      height: 100%;
      max-height: 500px;
    }
  }

  .ant-space-item > div {
    width: fit-content;
  }

  .right-col {
    position: relative;
    width: 50%;

    .notification-icon {
      position: absolute;
      width: 70px;
      height: 70px;
      top: -5%;
      left: -5%;
      z-index: 3;
    }
  }

  .col-gap {
    gap: 0 64px;

    @media (max-width: 1440.98px) {
      gap: 0 24px;
    }
  }
}
