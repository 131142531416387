.background-container-1 {
   position: relative;
   width: 100%;
   height: 80vh;

   .background-cover {
      position: absolute;
      width: 100%;
      min-width: 1880px;
      height: 1100px;
      overflow: hidden;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -99;

      @media (min-height: 1000px) {
         height: 1700px;
      }

      @media (min-height: 1679px) {
         height: 1864px;
      }

      .logo-container {
         width: 50%;
         justify-content: center;

         img {
            width: 100%;
            max-width: 400px;
            animation: float 6s ease-in-out infinite;
         }
      }

      .image-container {
         position: relative;
         width: 50%;
         height: 100%;
      }
   }

   #background-row-1 {
      position: absolute;
      width: 150%;
      bottom: 0;
      left: -60%;
      transform-origin: center;
   }

   #background-row-2 {
      position: absolute;
      width: 150%;
      bottom: 0;
      left: -35%;
      transform-origin: center;
   }

   #background-row-3 {
      position: absolute;
      width: 150%;
      bottom: 0;
      left: -10%;
      transform-origin: center;
   }

   #background-row-4 {
      position: absolute;
      width: 150%;
      bottom: 0;
      left: 15%;
      transform-origin: center;
   }

   .anim-slide-1 {
      -webkit-animation: slide_3 60s infinite;
      animation: slide_3 60s infinite;
      -moz-animation: slide_3 60s infinite;
      animation-timing-function: linear;
   }

   @keyframes slide_3 {
      0% {
         transform: translateY(-300px) rotate(45deg);
      }
      50% {
         transform: translateY(350px) rotate(45deg);
      }
      100% {
         transform: translateY(-300px) rotate(45deg);
      }
   }

   .anim-slide-2 {
      -webkit-animation: slide_4 60s infinite;
      animation: slide_4 60s infinite;
      -moz-animation: slide_4 60s infinite;
      animation-timing-function: linear;
   }

   @keyframes slide_4 {
      0% {
         transform: translateY(350px) rotate(45deg);
      }
      50% {
         transform: translateY(-300px) rotate(45deg);
      }
      100% {
         transform: translateY(350px) rotate(45deg);
      }
   }

   @keyframes float {
      0% {
         // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
         filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
         transform: translateY(0px);
      }
      50% {
         filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
         transform: translateY(-20px);
      }
      100% {
         filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
         transform: translateY (0px);
      }
   }
}
