@import "/public/style/value.scss";

.five-section {
  position: relative;
  height: $min-height;
  min-height: 900px;
  overflow: hidden;

  .title,
  .sub-title {
    font-size: $header-font-size;
    line-height: 2.375rem;
    margin-bottom: 0;
    text-align: center;
  }

  .title {
    font-weight: 400;
    color: #fff;
    margin-bottom: 8px;
  }

  .sub-title {
    color: $secondary-color;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .cover-image {
    position: relative;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      max-width: 80%;
      object-fit: cover;

      @media (max-width: 1440.98px) {
        max-width: 65%;
      }
    }
  }

  #text-one,
  #text-two,
  #text-three,
  #text-four,
  #text-five {
    p,
    b {
      font-size: $subheader-font-size;
      line-height: 1.625rem;
      color: #fff;
      margin-bottom: 8px;
    }

    b {
      color: $secondary-color;
    }
  }

  #text-one {
    position: absolute;
    top: 7%;
    left: 7%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1440.98px) {
      left: 10%;
    }
  }

  #text-two {
    position: absolute;
    bottom: -10%;
    left: 7%;
    margin-left: auto;
    margin-right: auto;
  }

  #text-three {
    position: absolute;
    bottom: 6%;
    left: 52%;
    margin-left: auto;
    margin-right: auto;
  }

  #text-four {
    position: absolute;
    bottom: -5%;
    right: 3%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1440.98px) {
      bottom: -4%;
      right: 5%;
    }
  }

  #text-five {
    position: absolute;
    top: 4%;
    right: 15%;
    margin-left: auto;
    margin-right: auto;
  }
}
