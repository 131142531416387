@import "/public/style/value.scss";

.second-section {
	padding-top: 0;
	height: 50vh;
	position: relative;

	#background {
		min-height: 80vh;
	}

	.content-container {
		max-width: 1440px;
	}

	h1 {
		font-size: $header-font-size;
		font-weight: 600;
		line-height: 2.375rem;
		margin-bottom: 24px;
		color: $primary-color;
		text-align: center;
	}

	.grid-container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1rem;
		padding: 0 1rem;

		img {
			margin-bottom: 8px;
		}

		p {
			font-size: 1rem;
			line-height: 1.375rem;
			font-weight: $font-weight-normal;
			color: $primary-color;
			margin-bottom: 0;

			@media (max-width: 1440.98px) {
				font-size: 0.875rem;
			}
		}
	}
}
