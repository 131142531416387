@import "/public/style/value.scss";

.footer {
  position: relative;
  top: 28%;
  min-height: 300px;

  > button {
    position: absolute;
    top: -15%;
    left: 50%;
    width: 100%;
    max-width: 300px;
    background-color: $secondary-color;
    color: #fff;
    border: none;
    height: 70px;
    font-size: $subheader-font-size;
    transform: translate(-50%, -50%);
  }

  .background-image {
    bottom: 0;
  }

  .top-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 16px;
    padding-top: 100px;
    height: 260px;

    .content-container {
      display: flex;
      justify-content: space-between;
      width: 100%;

      h4 {
        font-size: 1.25rem;
        color: #fff;
      }

      p {
        color: #fff;
        font-size: 1rem;
        margin-bottom: 0;
      }
    }

    .logo-image {
      width: 100%;
      height: auto;
      max-width: 140px;
    }

    .store-icon {
      max-width: 100px;
      width: 100%;
      height: auto;
    }
  }

  .bottom-footer {
    background-color: $primary-variant-color;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 5px 16px;
    color: #fff;

    .content-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}
