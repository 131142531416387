.terms-condition-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   max-width: 900px;
   min-height: 100vh;
   margin: 0 auto;
   font-size: 1rem;
   line-height: 1.6;
   margin-bottom: 10rem;

   ul {
      margin-bottom: 1rem;
   }

   p {
      margin-bottom: 1rem;
      line-height: 1.6;
   }

   p.title {
      margin-bottom: 0;
      font-weight: 600;
   }

   .text-indent-40 {
      text-indent: -40px;
      padding-left: 40px;
   }

   .text-indent {
      text-indent: 40px;
   }

   .margin-bottom-0 {
      margin-bottom: 0;
   }

   h1.header {
      font-size: 2em;
      font-weight: 600;
      margin: 2rem 0;
   }

   h2.text-indent {
      text-indent: 1.5rem;
   }

   h2.header {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: #000;
   }

   .padding-left-24 {
      padding-left: 1.5rem;
   }

   .padding-left-16 {
      padding-left: 1rem;
   }
}

.cookie-policy-container {
   width: 100%;
   max-width: 900px;
   min-height: 100vh;
   margin: 0 auto;
   margin-bottom: 10rem;

   h1 {
      font-size: clamp(1.25rem, 1.0333rem + 0.9245vw, 1.625rem);
      margin-bottom: 1rem;
      text-align: left;
      line-height: 1;
   }

   h1.header {
      font-size: 2em;
      font-weight: 600;
      margin: 2rem 0;
      color: #012875;
   }

   p {
      // margin-bottom: 0;
      color: var(--title);
   }

   ul {
      margin-bottom: 1rem;
      color: var(--title);
   }
}

.privacy-container {
   display: flex;
   flex-direction: column;
   gap: 3.5rem;
   width: 100%;
   max-width: 900px;
   min-height: 100vh;
   margin: 0 auto;
   margin-bottom: 10rem;

   h1 {
      font-size: clamp(1.25rem, 1.0333rem + 0.9245vw, 1.625rem);
      margin-bottom: 1rem;
      text-align: left;
      line-height: 1;
   }

   h1.header {
      font-size: 2em;
      font-weight: 600;
      margin: 2rem 0;
      color: #012875;
   }

   p {
      margin-bottom: 0;
      color: var(--title);
   }

   ul,
   li {
      margin-bottom: 0;
      color: var(--title);
   }
}
