@import "/public/style/value.scss";

.fourteen-section {
  .background-section {
    height: 73.5vh;
    position: relative;

    img {
      max-width: 100%;
      height: 100%;
      min-height: 800px;
      object-fit: cover;
      top: -10%;
      z-index: -3 !important;
      image-resolution: from-image 300dpi;
      image-rendering: optimizeQuality;
    }
  }

  h1.main-title {
    font-size: $header-font-size;
    font-weight: 500;
    color: #fff;
    margin-bottom: 0;
    text-align: center;

    b {
      color: $secondary-color;
    }

    p {
      margin-bottom: 0;
      font-weight: 400;
    }
  }

  h1.sub-title {
    width: 100%;
    text-align: center;
    font-size: 1.75rem;
    font-weight: 500;
    color: #fff;
    margin-bottom: 0;
    text-align: center;
    margin-bottom: 16px;

    b {
      color: $secondary-color;
    }
  }

  h2 {
    font-size: $subheader-font-size;
    font-weight: 400;
    text-align: center;
    max-width: 70ch;
    margin: 0 auto;
    color: #fff;

    > p {
      margin-bottom: 0;
    }

    > b {
      color: $secondary-color;
    }
  }

  .company-section {
    position: relative;
    height: 100vh;
    min-height: 900px;
    overflow: hidden;

    .image {
      width: 100%;
      max-width: 65%;
      height: auto;
    }
  }

  .concept-section {
    position: relative;
    height: 81vh;
    min-height: 39.375rem;
  }

  p.customer-details {
    color: white;
    font-size: 1.25rem;
    text-align: center;
  }

  .cover-image {
    min-width: 70px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    border-radius: 50px;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 1;
    }
  }

  .swiper-pagination-bullets-dynamic {
    bottom: 10% !important;
  }

  .swiper-pagination-bullet {
    background: #f1f1f1;
    opacity: 0.6;
  }

  .swiper-pagination-bullet-active {
    background: $secondary-color;
    opacity: 1;
  }

  .swiper {
    padding: 3% 15px;
    margin: -3% -15px;
    max-width: 1376px;
  }

  .skeleton-item {
    cursor: not-allowed;
    background-color: #f1f1f1;
    width: auto !important;
    height: auto;
    overflow: hidden;
    border-radius: 50%;
  }
}
