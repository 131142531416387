@import "/public/style/value.scss";

.svg-container {
  position: relative;
  min-width: 1366px;
  min-height: 725px;
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

.svg-object-1 {
  .layer-one {
    position: absolute;
    top: 0;
    left: 35%;
  }

  .layer-two {
    position: absolute;
    top: 18%;
    right: 11%;
  }

  .layer-three {
    position: absolute;
    top: 4%;
    right: 29%;

    img {
      max-width: 70% !important;
    }
  }

  .layer-four {
    position: absolute;
    top: 44%;
    right: 19%;
  }

  .layer-five {
    position: absolute;
    bottom: 29%;
    left: 25%;

    img {
      max-width: 70% !important;
    }
  }

  .layer-six {
    position: absolute;
    bottom: 21%;
    left: 20%;
  }

  .layer-seven {
    position: absolute;
    top: 10%;
    left: 34%;
  }

  .layer-eight {
    position: absolute;
    bottom: 17%;
    right: 24%;
  }

  .layer-nine {
    position: absolute;
    bottom: 7%;
    right: 29%;

    img {
      max-width: 70% !important;
    }
  }

  .layer-ten {
    position: absolute;
    bottom: 4%;
    left: 19%;
  }
}

.svg-object-2 {
  .layer-one {
    position: absolute;
    top: 10%;
    left: 35%;
    z-index: 1;
  }

  .layer-two {
    position: absolute;
    top: 10%;
    right: 10%;
  }

  .layer-three {
    position: absolute;
    top: 23%;
    right: 0;
  }

  .layer-four {
    position: absolute;
    top: 45%;
    left: 16%;
  }

  .layer-five {
    position: absolute;
    top: 54%;
    left: 26%;
  }

  .layer-six {
    position: absolute;
    bottom: 0;
    right: 42%;
  }

  .layer-seven {
    position: absolute;
    top: 33%;
    left: 30%;
  }

  .layer-eight {
    position: absolute;
    bottom: 27%;
    right: 14%;
  }

  .layer-nine {
    position: absolute;
    bottom: 7%;
    right: 29%;

    img {
      max-width: 70% !important;
    }
  }

  .layer-ten {
    position: absolute;
    bottom: 0;
    left: 15%;
  }
}
