.redirect-sso{
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .counting-down-section{
  
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
        .timer{
            position: absolute;
            top: 50%;
            left:  50%;
            transform: translate(-50%,-50%);
            font-size: 32px;
            font-weight: 500;
            font-family: "Prompt",
            sans-serif;
        }
        .continue-button{
            border: none;
            border-radius: 20px;
            // font-weight: 500 !important;
            font-size: 16px;
            cursor: pointer;
            height: 2.5rem;
            background-color: #007aff;
            color: white;
            padding-inline: 1rem;
        }
    }

   
}