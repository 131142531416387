@import '/public/style/value.scss';

.hibrary-new-section {
   height: 80vh;
   min-height: 800px;
   margin-top: 4%;

   h1 {
      font-size: $header-font-size;
      font-weight: $font-weight-medium;
      line-height: 2.375rem;
      margin-bottom: 24px;
      text-align: center;
      margin-bottom: 0;

      > b {
         color: $primary-color;
      }
   }

   .card-box {
      position: relative;
      width: 100%;
      height: 420px;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
         rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      background-color: #fff;
      transition: 0.3s ease-in-out;

      &:hover {
         transform: scale(1.1);

         .clip-path-cover {
            animation: 0.3s cover-anim ease-in-out;
            -webkit-clip-path: ellipse(100% 74% at 64% 43%);
            clip-path: ellipse(100% 74% at 64% 43%);

            img {
               transform: scale(1.05);
               animation: 0.3s image-anim ease-in-out;
            }
         }

         .description-container {
            animation: 0.3s description-anim ease-in-out;
            margin-top: 70%;

            @media (max-width: 1440.98px) {
               margin-top: 52%;
            }
         }
      }
   }

   .news-cover-image {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;

      .clip-path-cover {
         -webkit-clip-path: ellipse(100% 75% at 65% 0%);
         clip-path: ellipse(100% 75% at 65% 0%);
         transition: 0.3s ease-in-out;
         max-height: 220px;
         overflow: hidden;
      }

      img {
         width: 100%;
         height: auto;
         min-height: 220px;
         object-fit: cover;
      }
   }

   .description-container {
      padding: 0.5rem;
      margin-top: 55%;
      transition: 0.3s ease-in-out;

      @media (max-width: 1440.98px) {
         margin-top: 45%;
      }

      .new-title {
         font-size: 1rem;
         font-weight: $font-weight-bold;
         color: #000;
         margin-bottom: 0.3rem;
         display: -webkit-box;
         -webkit-line-clamp: 2;
         -webkit-box-orient: vertical;
         overflow: hidden;

         &:hover {
            text-decoration: underline;
         }
      }

      .description {
         font-size: 0.875rem;
         font-weight: $font-weight-normal;
         display: -webkit-box;
         -webkit-line-clamp: 4;
         -webkit-box-orient: vertical;
         overflow: hidden;
      }
   }

   .publisher {
      color: $grey-3;
      font-size: 0.75rem;
      font-weight: $font-weight-light;
      margin-top: 8px;
   }

   .content-container {
      max-width: 1880px;
      padding: 0 1.5rem;
      width: 100%;
   }

   //? custom-swiper
   .swiper {
      // overflow: hidden;
      padding: 4rem 1.5rem;
      margin: -4rem -1.5rem;
   }

   .swiper-pagination-bullets-dynamic {
      bottom: 3 !important;
   }

   .swiper-pagination-bullet-active {
      background: $secondary-color;
      opacity: 1;
   }

   //? animation
   @keyframes cover-anim {
      0% {
         clip-path: ellipse(100% 75% at 65% 0%);
      }

      100% {
         clip-path: ellipse(100% 74% at 64% 43%);
      }
   }

   @media (min-width: 1441) {
      @keyframes description-anim {
         0% {
            margin-top: 55%;
         }

         100% {
            margin-top: 70%;
         }
      }
   }

   @media (max-width: 1440.98px) {
      @keyframes description-anim {
         0% {
            margin-top: 45%;
         }

         100% {
            margin-top: 55%;
         }
      }
   }

   @keyframes image-anim {
      0% {
         transform: scale(1);
      }

      100% {
         transform: scale(1.05);
      }
   }
}
