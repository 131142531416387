.register-field {
	width: 100%;
	height: 100vh;
	position: relative;
	transform: translateY(-9.5rem);

	#background {
		height: 100vh;
	}

	.text-background {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: auto !important;
		max-width: 1600px;
		z-index: 2;
		cursor: pointer;
	}
}
