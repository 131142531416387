@import '/public/style/value.scss';

.contact-modal {
   .cover-image {
      width: 100%;
      height: auto;
      margin-bottom: 24px;
   }

   > div {
      width: 100%;
   }

   .detail {
      color: $primary-color;
      margin-bottom: 0;
      font-size: $subheader-font-size;
      font-weight: $font-weight-normal;

      > b {
         color: $secondary-color;
         font-weight: $font-weight-medium;
      }
   }
}

.register-modal {
   padding: 1rem !important;

   a {
      width: 100%;
      text-align: center;
      color: $primary-color;
      text-decoration: underline;

      &:hover {
         transition: 0.3s;
         color: #012875bc;
      }
   }

   label {
      color: $grey-3;
   }

   form {
      width: 100%;
      display: flex;

      flex-direction: column;
      gap: 1rem;

      button {
         color: #fff;
         background: $primary-color;
         border: none;
         width: 100%;
         max-width: 200px;
         height: 3rem;

         &:hover {
            color: #fff;
            background: #012875bc;
         }

         &:focus {
            background: $primary-color;
            color: #fff;
         }
      }
   }

   .ant-space-vertical {
      width: 100%;
   }

   .ant-radio-wrapper {
      margin: 0 1rem 0 0;
   }

   .ant-radio-checked .ant-radio-inner {
      border-color: $primary-color;
   }

   .ant-radio-inner:after {
      background-color: $primary-color;
   }

   .ant-input {
      border: 1px solid $grey-7;
      border-radius: 0.375rem;
      width: 100%;
      margin-top: 0.25rem;
      height: 2.75rem;
   }

   .header {
      width: 100%;
      text-align: center;
      margin-bottom: 1.5rem !important;
   }

   .margin-bottom-4 {
      margin-bottom: 4px;
   }

   .error-text {
      margin-top: 0.5rem;
      color: #ff4d4f;
   }

   .button-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
   }
}

.modal-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.header {
   font-size: $subheader-font-size;
   font-weight: $font-weight-medium;
   color: $primary-color;
   margin-bottom: 0;
}

.detail {
   display: flex;
   flex-direction: column;
   gap: 4px;

   span {
      color: $grey-3;

      &:nth-child(1) {
         font-weight: 500;
      }

      &:nth-child(2) {
         color: $primary-color;
      }
   }
}
