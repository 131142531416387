$primary-color: #012875;
$secondary-color: #fd6b53;
$primary-variant-color: #001b51;
$grey-3: #4f4f4f;
$grey-7: #f2f2f2;

$min-height: 100vh;
$content-padding: 48px;

$header-font-size: 2.25rem;
$subheader-font-size: 1.5rem;

$font-weight-bold: 600;
$font-weight-medium: 500;
$font-weight-normal: 400;
$font-weight-light: 300;
