@import "/public/style/value.scss";

.section {
  position: relative;
  justify-content: space-evenly;
  padding: calc($content-padding + 16px) 0;
  overflow: hidden;
}

.nine-section {
  position: relative;
  height: 100%;

  .gap-8 {
    gap: 12px;
  }

  h1 {
    font-size: $header-font-size;
    line-height: 2.25rem;
    font-weight: $font-weight-normal;
    text-align: center;

    > b {
      color: $secondary-color;
    }
  }

  h2 {
    font-size: $subheader-font-size;
    line-height: 1.625rem;
    font-weight: $font-weight-normal;
    text-align: center;
  }

  .content-container {
    padding: calc($content-padding + 32px);
  }

  .image-cover {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.ten-section {
  position: relative;
  height: 100%;
  transform: translateY(48px);
  overflow: hidden;

  .content-container {
    padding: calc($content-padding + 32px);
  }

  h1 {
    font-size: $header-font-size;
    line-height: 2.25rem;
    font-weight: $font-weight-normal;
    text-align: center;

    > b {
      color: $secondary-color;
    }
  }

  h2 {
    font-size: $subheader-font-size;
    line-height: 1.625rem;
    font-weight: $font-weight-normal;
    text-align: center;
  }

  .title-section {
    margin-bottom: 0;
  }

  .hand {
    width: 90%;
    height: auto;
  }

  .search {
    width: auto;
    height: 100%;
    max-height: 70px;
  }

  .comment {
    position: absolute;
    top: -5%;
    left: -19%;
  }

  .line-1 {
    position: absolute;
    top: 48%;
    left: 5%;
    width: 0em;
    border-right: 2px solid #000;
    font-size: 180%;
    font-weight: 300;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
  }

  .anim-typewriter {
    animation: typewriter 1.5s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }

  @keyframes typewriter {
    from {
      width: 0em;
    }
    to {
      width: 8em;
    }
  }

  @keyframes blinkTextCursor {
    from {
      border-right-color: #000;
    }
    to {
      border-right-color: transparent;
    }
  }

  .image-cover {
    position: relative;
    max-height: 600px;
  }
}
